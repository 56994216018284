import { render, staticRenderFns } from "./MynaviCompFooter.vue?vue&type=template&id=a22ec418&scoped=true&"
import script from "./MynaviCompFooter.vue?vue&type=script&lang=js&"
export * from "./MynaviCompFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a22ec418",
  null
  
)

export default component.exports