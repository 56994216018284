<template>
  <div id="GlobalFooter" class="top_page_footer">
    <div id="FooterCorp">
      <div class="footer_sitedetail_nav_list">
        <ul class="footer_sitedetail_nav_list_detail">
          <li>
            <a href="/contact" class="first_a"><span>お問い合わせ</span></a>
          </li>
          <li>
            <a href="https://business.mynavi-suki-iki.jp" target="_blank"><span>人材をお探しの企業様</span></a>
          </li>
          <li>
            <a href="https://business.mynavi-suki-iki.jp/contact" target="_blank"><span>掲載問い合わせ</span></a>
          </li>
          <li>
            <a href="/terms"><span>会員規約</span></a>
          </li>
          <li>
            <a href="/privacy1"
              ><span
                >個人情報の取り扱いについて<br
                  class="delpc"
                />（スキイキ）</span
              ></a
            >
          </li>
          <li>
            <a href="https://note.com/suki_iki/" target="_blank"
              ><span>スキイキ！プロ活らぼ</span></a
            >
          </li>
        </ul>
      </div>
      <div id="FooterCorpInfo">
        <div id="FooterCorpId">
          <div id="FooterCorpName">
            <a
              href="https://www.mynavi.jp/"
              target="_blank"
              rel="nofollow noopener"
            >
              <img
                v-lazy="`/img/cmn/corp_name.gif`"
                width="100"
                height="13"
                alt="株式会社マイナビ"
              />
            </a>
          </div>
          <p id="copyright">Copyright © Mynavi Corporation</p>
        </div>
        <div id="FooterCorpLink">
          <ul>
            <li>
              <a
                href="https://www.mynavi.jp/company/"
                target="_blank"
                rel="nofollow noopener"
                >会社概要</a
              >
            </li>
            <li>
              <a
                href="https://www.mynavi.jp/company/office.html"
                target="_blank"
                rel="nofollow noopener"
                >事業所案内</a
              >
            </li>
            <li>
              <a
                href="https://www.mynavi.jp/company/social.html"
                target="_blank"
                rel="nofollow noopener"
                >社会的な取り組み</a
              >
            </li>
            <li>
              <a
                href="https://www.mynavi.jp/recruit/"
                target="_blank"
                rel="nofollow noopener"
                >採用情報</a
              >
            </li>
            <li>
              <a
                href="https://www.mynavi.jp/group/"
                target="_blank"
                rel="nofollow noopener"
                >グループ会社</a
              >
            </li>
            <li class="last">
              <a
                href="https://www.mynavi.jp/privacy/"
                target="_blank"
                rel="nofollow noopener"
                >個人情報保護方針</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div id="privacy">
      <a href="https://privacymark.jp/" target="_blank" rel="nofollow noopener">
        <img
          v-lazy="`https://conts1.mynv.jp/privacy.png`"
          width="75"
          height="75"
          alt="プライバシーマーク"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopPageFooter",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
